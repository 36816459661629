/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { QueryClient, keepPreviousData, useQuery } from '@tanstack/react-query';
import { createFlashDealsRequest, mapZooverFlashDealsResponse } from '@vakantiesnl/services/src/flash-deals';
import { errorHandler, parseErrorToReport } from '@vakantiesnl/services/src/util/errorHandling';
import { fetchVakNL } from '@vakantiesnl/services/src/util/vaknl-fetch';
import { FlashDeals, Search } from '@vakantiesnl/types';
import { shuffle } from 'lodash';

function getFlashDealsQueryKey(args: Search.VaknlSearchBody, shuffleAndTake?: number) {
	return ['flash-deals', JSON.stringify(args), shuffleAndTake || ''];
}

export function useGetFlashDeals(args: Search.VaknlSearchBody, shuffleAndTake?: number) {
	const query = useQuery({
		queryKey: getFlashDealsQueryKey(args, shuffleAndTake),
		queryFn: () => fetchFlashDeals(args, shuffleAndTake),
		placeholderData: keepPreviousData,
	});

	return {
		flashDeals: query.data?.flashDeals ?? [],
		flashDealsTotal: query.data?.total ?? 0,
		isFlashDealsLoading: query.isFetching,
	};
}

export async function prefetchFlashDeals(
	queryClient: QueryClient,
	args: Search.VaknlSearchBody,
	shuffleAndTake?: number,
) {
	try {
		await queryClient.prefetchQuery({
			queryKey: getFlashDealsQueryKey(args, shuffleAndTake),
			queryFn: () => fetchFlashDeals(args, shuffleAndTake),
		});
	} catch (e) {
		errorHandler.report(parseErrorToReport(e, 'Error prefetching flash deals'));
	}
}

async function fetchFlashDeals(body: Search.VaknlSearchBody, shuffleAndTake?: number) {
	const query = createFlashDealsRequest(body);

	try {
		const response = await fetchVakNL({ input: query });
		const result: FlashDeals.FlashDealsResponse = await response.json();
		const flashDeals = mapZooverFlashDealsResponse(result.flash_deals);

		return {
			flashDeals: shuffleAndTake ? shuffle(flashDeals).slice(0, shuffleAndTake) : flashDeals,
			total: result.flash_deals_count,
		};
	} catch (e) {
		errorHandler.report(parseErrorToReport(e, `Error fetching flash deals with data ${JSON.stringify(body)}`));

		throw new Error('Cannot fetch flash deals');
	}
}
